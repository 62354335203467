import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const MyndighetR1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="694.6px"
        height="1137.1px"
        viewBox="0 0 694.6 1137.1"
      >
        <GrainPattern id={id} x="100%" />
        <path
          fill={`url(#${id})`}
          d="M694.6,0c-13.7,8.1-26.8,16.3-39.1,24.8C610.8,55.6,568.6,64.4,528.7,98c-41.7,35-94.1,96.4-143.2,126.4
	c-47,28.8-83.6,42.7-112.2,95.3c-34.8,11.4-67.6,38.2-100.9,58.2c-65.5,39.5-60.2,52.8-94.7,101.9c-16.9,20-27.7,39.1-45.8,65.2
	C18.5,559.7-1.3,594,0.1,625.6c1.4,29.7,2.7,35.5,18.5,66.8c12.9,26.5,16.4,39,43,74.5c25,34.9,79.7,59.6,93.8,82.8
	c16.2,26.6,94.5,54.9,102,86c14.3,59.4-28.6,68.6-8.1,124.9c16.8,46,43.5,80.9,124.1,76.2c77.1-4.5,36.1,6.3,121.4-37.3
	c71.4-36.4,133.7-42.7,199.7-58.5V0z"
        />
      </svg>
    )
  }
)

export default MyndighetR1
