import React, { FunctionComponent, ReactChildren } from "react"
import styles from "./projektet-yalla-rinkeby-2.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { graphql } from "gatsby"
import Image, { FluidObject } from "gatsby-image"
import cx from "classnames"
import MyndighetR1 from "../components/blobs/myndighet-r-1"
import MyndighetT1 from "../components/blobs/myndighet-t-1"
import useParallax, { makeTransformY } from "../hooks/use-parallax"
import { ProjektetQuery } from "../generated/graphql"
import { oc } from "ts-optchain"
import renderAst from "../lib/render-ast"

const YallKafePage: FunctionComponent<{ data: ProjektetQuery }> = ({
  data: { page },
}) => {
  const refBlobR = useParallax<SVGSVGElement>(null, makeTransformY(1))
  const refBlobT = useParallax<SVGSVGElement>(
    null,
    makeTransformY(-0.8, "-50%")
  )

  return (
    <Layout>
      <SEO title={oc(page).title("Yalla Kafe")} />
      <MyndighetT1 className={styles.blobT} ref={refBlobT} />
      <MyndighetR1 className={styles.blobR} ref={refBlobR} />
      <Hero backgroundColor="purple-dark">
        <h1>{oc(page).heroTitle()}</h1>
        {renderAst(oc(page).heroText.childMarkdownRemark.htmlAst())}
      </Hero>
      <div className={styles.main}>
        <section className={cx("container", styles.grid)}>
          <div>{renderAst(oc(page).text.childMarkdownRemark.htmlAst())}</div>
          <div>
            {oc(page).image.localFile.childImageSharp.fluid() ? (
              <Image
                className={styles.image}
                fluid={
                  oc(
                    page
                  ).image.localFile.childImageSharp.fluid() as FluidObject
                }
                alt=""
              />
            ) : null}
            {oc(page).splashTitle() || oc(page).splashText.childMarkdownRemark.htmlAst() ?
              <div className={styles.splash}>
                <h3 className={styles.supportersTitle}>
                  {oc(page).splashTitle()}
                </h3>
                {renderAst(oc(page).splashText.childMarkdownRemark.htmlAst(), {
                  p: ({ children }: { children: ReactChildren }) => (
                    <p className={styles.supporters}>{children}</p>
                  ),
                })}
                <svg
                  className={styles.splashBg}
                  width="582.2px"
                  height="515.3px"
                  viewBox="0 0 582.2 515.3"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.5,347.3c1.7-14.2,8-27.4,14.5-40.7c6.9-14,13.5-28.1,13.8-43.2
      c0.3-17.3-5.3-34.6-8.6-51.6c-6.3-32.7-10.2-65.5-14-98.4c-3.1-26.9-1-51.1,25.3-71.3C56.7,22.8,91.8,14.7,126.7,12
      c43.1-3.4,86.9,1.1,129.7-4.6c19.4-2.6,38.5-7.8,58.4-7.4c18.2,0.3,35,5.6,51.8,10.4c19.2,5.6,37.5,8.6,58.2,8.2
      c18.8-0.3,37.7-2,56.4,0.4c28.2,3.6,50,17.7,58.4,38.3c4.6,11.2,5.2,23.1,4.1,34.7c0.8,23.4,19.8,43,18.3,66.7
      c-0.4,5.7-1.1,11.4-2.1,17c-0.4,2.4-0.8,4.8-1.2,7.4c-0.6,3.7,0.3,5.7,0,9.6c-0.3,3.6-1.7,9.3-1.4,13.1c0.1,1.5,0.3,3,0.5,4.6
      c0.4,3.5,2.7,7.1,3.5,8.7c1.2,5.9,1.2,13.6,3,19.5c0.8,2.5,1.6,5,2.4,7.6c0.4,1.2,0.8,2.4,1.2,3.7c1.4,4.2,2.8,8.4,4.2,12.7
      c1.9,5.7,3.5,12.2,3.6,15.6c1.5,5.6,4.4,13,5.3,18.8c0.4,2.4,0.7,4.8,0.9,7.4c0.3,4,0.4,8.1,0.2,12.2c-0.2,4.2-0.8,8.3-1.6,12.4
      c-0.7,3-1.5,5.9-2.6,8.7c-0.9,2.6-2,5.2-3.3,7.8c-2.4,5-5.2,9.8-8.2,14.4c-1.5,2.4-4.1,4.8-5.8,7.2c-3.3,4.8-5.2,11.3-8.6,16.1
      c-1.4,1.8-5.5,5.2-7.9,8.8c-1.8,2.7-3.5,5.6-5.1,8.4c-0.9,1.5-1.7,3.1-2.4,4.7c-1,2-1.8,4-2.6,6.1c-1.8,4.8-2.9,9.9-3.3,15
      c-0.2,3-0.7,4.7-0.4,7.9c0.2,1.8,1,5,1.4,7c0.7,3-0.4,7.7-0.1,9.1c1,5,3.8,10.2,3.4,15.3c-0.4,4.7-1.9,9.2-4.5,13.1
      c-1.4,2.2-3,4.3-4.8,6.2c-1.1,1.2-2.3,2.4-3.6,3.5c-3.4,3-7.1,5.6-11,7.8c-3.2,1.8-6.5,3.4-9.8,4.9c-4.3,1.8-8.7,3.4-13.2,4.8
      c-6,1.8-9.7,4.1-16.1,5.3c0.2-0.4-6.2-0.2-8.1,0.1c-2.2,0.4-4.5,0.7-6.8,1c-3.5,0.5-7,0.8-10.4,1.1c-1.9,0.2-4.7,0.1-6.7,0.2
      c-4.2,0.3-7.4,0.8-11.6,0.9c-3.2,0.1-6.5,0.2-9.8,0.3c-2.7,0.1-5.5,0.1-8.3,0.1c-3.2,0-6.4,0-9.7,0.1c-4.1,0-8.2,0-12.4,0
      c-20.4,0-40.8-0.3-61-2.8c-19.7-2.4-38.6-7.4-57.3-12.5c-19.6-5.3-40.5-12-61.4-12.8c-20.1-0.8-32.8,11.1-50.1,16.6
      c-13.4,4.2-27.5,6.2-41.6,5.7c-27.9-0.8-54.2-10.9-68.2-29.5c-9.5-12.8-15.2-27.5-21.8-41.2c-7.3-15.2-14.7-30.4-20-46.1
      C1.9,378-1.3,362.6,0.5,347.3z"
                  />
                </svg>
              </div>
            : null}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Projektet($id: String!) {
    page: contentfulProjektet20(id: { eq: $id }) {
      title
      heroTitle
      heroText {
        childMarkdownRemark {
          htmlAst
        }
      }
      text {
        childMarkdownRemark {
          htmlAst
        }
      }
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 565) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      splashText {
        childMarkdownRemark {
          htmlAst
        }
      }
      splashTitle
    }
  }
`

export default YallKafePage
