import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const MyndighetT1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="896.3px"
        height="333px"
        viewBox="0 0 896.3 333"
      >
        <GrainPattern id={id} x="50%" />
        <path
          fill={`url(#${id})`}
          d="M0,0c1.6,1.6,3.3,3.2,4.9,4.7C12.3,11.5,20,17.9,28,23.9C39,32,50.4,39.5,62.2,46.4c21.5,6.6,34,17,45.1,23.1
	c0.5,0.2-2.8-1.5,13.5,6.6c4.9,3.2,13.3,8.6,13.7,8.4c3.4,1.7,7.8,2.4,10.9,4c11.9,6,33.4,19.1,33.6,19.4
	c10.3,5.8,20,11.8,29.5,18.2c4,2.7,7.7,5.4,11.4,8.1c4.1,3,11,4.2,14.6,7.3c4.3,0.4,13.4,17.2,20.9,25.1c5.6,5.8,11,11.6,16.3,17.2
	c4.6,4.8,9.1,9.6,13.6,13.9c3.3,3.2,6.3,6.2,9.5,9.1c5.9,5.3,12.2,10.2,18.8,14.4c10.5,6.6,21.5,12.3,32.9,17.2
	c15.8,7,16.4,7.2,31.6,14.7c5.4,2.7,26.2,11.4,31.1,14.3c5.9,3.5,11.7,7.5,17.1,11.8c6.3,6,12.8,11.9,19.5,17.2
	c7.2,5.8,14.7,11.2,22.6,16.1c6.7,4.2,13.7,7.8,20.9,11c10.3,4.5,21.3,7.7,32.4,9.4c0.6,0.1,1.1,0.2,1.7,0.2h22.9
	c6-1.1,11.9-3.3,18.7-7c9.9-5,21.1-1.6,28.8-5.8c7-3.8,13.6-8.3,19.8-13.5c4.4-3.7,8.2-12.8,14.2-16.8c8.6-3.3,22-22.6,33-37
	c3.8-4.9,7.4-9.6,11-14.2c4.8-6,6.2-11.1,14.7-16.6c15.8-8.5,27.3-24.1,43-33.7c5.2-3.2,10.3-6,15.6-8.9c6.6-3.5,13.2-7,19.5-10.4
	c9.6-10.9,24.6-14.2,35.3-22.8c5.6-4.5,10.8-9.4,15.6-14.8c6.2-7,11.1-8.3,16.2-22.5c7.3-12.1,13.3-24.9,19.5-37.3
	c3.7-7.5,1.6-19.3,5.6-26.2c-0.1,1.3,20.7-21.1,20.7-21.1s10.1-13,19-24.4H0z"
        />
      </svg>
    )
  }
)

export default MyndighetT1
